@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?lhcyfi122');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?lhcyfi#iefix122') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?lhcyfi122') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?lhcyfi122') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?lhcyfi122##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-fm-"], [class*=" icon-fm-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fm-replay {
  &:before {
    content: $icon-fm-replay; 
  }
}
.icon-fm-redo {
  &:before {
    content: $icon-fm-redo; 
  }
}
.icon-fm-remove_circle1 {
  &:before {
    content: $icon-fm-remove_circle1; 
  }
}
.icon-fm-remove_circle_outline1 {
  &:before {
    content: $icon-fm-remove_circle_outline1; 
  }
}
.icon-fm-undo {
  &:before {
    content: $icon-fm-undo; 
  }
}
.icon-fm-insert_emoticon {
  &:before {
    content: $icon-fm-insert_emoticon; 
  }
}
.icon-fm-drive_eta {
  &:before {
    content: $icon-fm-drive_eta; 
  }
}
.icon-fm-time_to_leave {
  &:before {
    content: $icon-fm-time_to_leave; 
  }
}
.icon-fm-timelapse {
  &:before {
    content: $icon-fm-timelapse; 
  }
}
.icon-fm-hourglass_bottom {
  &:before {
    content: $icon-fm-hourglass_bottom; 
  }
}
.icon-fm-trending_down {
  &:before {
    content: $icon-fm-trending_down; 
  }
}
.icon-fm-trending_up {
  &:before {
    content: $icon-fm-trending_up; 
  }
}
.icon-fm-filter_hdr {
  &:before {
    content: $icon-fm-filter_hdr; 
  }
}
.icon-fm-landscape {
  &:before {
    content: $icon-fm-landscape; 
  }
}
.icon-fm-terrain {
  &:before {
    content: $icon-fm-terrain; 
  }
}
.icon-fm-accessible_forward {
  &:before {
    content: $icon-fm-accessible_forward; 
  }
}
.icon-fm-accessibility {
  &:before {
    content: $icon-fm-accessibility; 
  }
}
.icon-fm-error {
  &:before {
    content: $icon-fm-error; 
  }
}
.icon-fm-warning {
  &:before {
    content: $icon-fm-warning; 
  }
}
.icon-fm-remove_circle {
  &:before {
    content: $icon-fm-remove_circle; 
  }
}
.icon-fm-remove_circle_outline {
  &:before {
    content: $icon-fm-remove_circle_outline; 
  }
}
.icon-fm-gps_not_fixed {
  &:before {
    content: $icon-fm-gps_not_fixed; 
  }
}
.icon-fm-collections {
  &:before {
    content: $icon-fm-collections; 
  }
}
.icon-fm-image_search {
  &:before {
    content: $icon-fm-image_search; 
  }
}
.icon-fm-person_add {
  &:before {
    content: $icon-fm-person_add; 
  }
}
.icon-fm-star {
  &:before {
    content: $icon-fm-star; 
  }
}
.icon-fm-star_half {
  &:before {
    content: $icon-fm-star_half; 
  }
}
.icon-fm-star_outline {
  &:before {
    content: $icon-fm-star_outline; 
  }
}
.icon-fm-check_circle {
  &:before {
    content: $icon-fm-check_circle; 
  }
}
.icon-fm-favorite {
  &:before {
    content: $icon-fm-favorite; 
  }
}
.icon-fm-favorite_outline {
  &:before {
    content: $icon-fm-favorite_outline; 
  }
}
.icon-fm-highlight_remove {
  &:before {
    content: $icon-fm-highlight_remove; 
  }
}
.icon-fm-info {
  &:before {
    content: $icon-fm-info; 
  }
}
.icon-fm-info_outline {
  &:before {
    content: $icon-fm-info_outline; 
  }
}
.icon-fm-search {
  &:before {
    content: $icon-fm-search; 
  }
}
.icon-fm-delete_forever {
  &:before {
    content: $icon-fm-delete_forever; 
  }
}
.icon-fm-check_circle_outline {
  &:before {
    content: $icon-fm-check_circle_outline; 
  }
}
.icon-fm-delete_outline {
  &:before {
    content: $icon-fm-delete_outline; 
  }
}
.icon-fm-person_remove {
  &:before {
    content: $icon-fm-person_remove; 
  }
}
.icon-fm-mobile-devices {
  &:before {
    content: $icon-fm-mobile-devices; 
  }
}
.icon-fm-left {
  &:before {
    content: $icon-fm-left; 
  }
}
.icon-fm-right {
  &:before {
    content: $icon-fm-right; 
  }
}
.icon-fm-close {
  &:before {
    content: $icon-fm-close; 
  }
}
.icon-fm-menu {
  &:before {
    content: $icon-fm-menu; 
  }
}
.icon-fm-calendar {
  &:before {
    content: $icon-fm-calendar; 
  }
}
.icon-fm-filter {
  &:before {
    content: $icon-fm-filter; 
  }
}
.icon-fm-map {
  &:before {
    content: $icon-fm-map; 
  }
}
.icon-fm-route {
  &:before {
    content: $icon-fm-route; 
  }
}
.icon-fm-settings {
  &:before {
    content: $icon-fm-settings; 
  }
}
.icon-fm-stat {
  &:before {
    content: $icon-fm-stat; 
  }
}
.icon-fm-users {
  &:before {
    content: $icon-fm-users; 
  }
}