// Modals

// modal content
.modal-content {
  overflow: hidden;
  // modal header
  border-radius: 0;
  .modal-header {
    border-radius: 0;
    &[class*="bg-"] > * {
      color: $white;
    }
  }
  // modal footer
  .modal-footer {
    .btn {
      margin: 0;
      & + .btn {
        margin-left: 8px;
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .modal-open .modal{
    padding: 0 .5rem !important;
  }
}

.confirm.modal-body{
  text-align: center;
    padding: 25px;
    background-color: #fff;
    color: $black;
    font-weight: 600;
    .content{
      margin-bottom: 20px;
    }
    .buttons{
      display: flex;
      justify-content: space-evenly;
      button{
        border-radius: 0;
      }
    }
}

.confirm-modal {
  width: 360px !important;
  margin-top: 120px;
  margin: 30px auto;
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .confirm {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    min-height: 150px;
    .content {
      padding: 20px;
      border-radius: 10px;
      background-color: #fff !important;
      font-weight: 700;
    }
    .buttons {
      padding: 10px 15px;
      float: right;
      button {
        margin-right: 10px;
      }
    }
  }
}


.full-screen-modal{
  .modal-header{
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    h2{
      margin: 0;
      font-size: 22px;
    }
  }
  .card-header{
    background: no-repeat;
    color: #000;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .modal-content{
    overflow: visible;
  }
  .modal-dialog {
    width: 90%;
    max-width: 90%;
  }
  .modal-body {
    height: calc(95vh - 100px);
    overflow-y: auto;
  }
  .close{
    border-radius: 100%;
    position: absolute;
    right: 0;
    background-color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    font-size: 2em;
    opacity: 1;
  }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus{
    opacity: 1;
  }
}