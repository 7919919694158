  .alert-light-danger {
    background-color: lighten($danger, 50%) !important;
    color: $danger !important;
    border-color: lighten($danger, 50%) !important;
  }
  .alert-light-warning {
    background-color: lighten($warning, 50%) !important;
    color: $warning !important;
    border-color: lighten($warning, 50%) !important;
  }
  .alert-light-success {
    background-color: lighten($success, 50%) !important;
    color: $success !important;
    border-color: lighten($success, 50%) !important;
  }