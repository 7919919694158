$icomoon-font-family: "fm-icons" !default;
$icomoon-font-path: "/assets/fonts/fm-icons" !default;

$icon-fm-replay: unquote('"\\e903"');
$icon-fm-redo: unquote('"\\e904"');
$icon-fm-remove_circle1: unquote('"\\e905"');
$icon-fm-remove_circle_outline1: unquote('"\\e906"');
$icon-fm-undo: unquote('"\\e907"');
$icon-fm-insert_emoticon: unquote('"\\e908"');
$icon-fm-drive_eta: unquote('"\\e909"');
$icon-fm-time_to_leave: unquote('"\\e909"');
$icon-fm-timelapse: unquote('"\\e928"');
$icon-fm-hourglass_bottom: unquote('"\\e929"');
$icon-fm-trending_down: unquote('"\\e92a"');
$icon-fm-trending_up: unquote('"\\e92b"');
$icon-fm-filter_hdr: unquote('"\\e92c"');
$icon-fm-landscape: unquote('"\\e92c"');
$icon-fm-terrain: unquote('"\\e92c"');
$icon-fm-accessible_forward: unquote('"\\e92d"');
$icon-fm-accessibility: unquote('"\\e92e"');
$icon-fm-error: unquote('"\\e912"');
$icon-fm-warning: unquote('"\\e913"');
$icon-fm-remove_circle: unquote('"\\e914"');
$icon-fm-remove_circle_outline: unquote('"\\e915"');
$icon-fm-gps_not_fixed: unquote('"\\e916"');
$icon-fm-collections: unquote('"\\e917"');
$icon-fm-image_search: unquote('"\\e918"');
$icon-fm-person_add: unquote('"\\e919"');
$icon-fm-star: unquote('"\\e91a"');
$icon-fm-star_half: unquote('"\\e91b"');
$icon-fm-star_outline: unquote('"\\e91c"');
$icon-fm-check_circle: unquote('"\\e91d"');
$icon-fm-favorite: unquote('"\\e91e"');
$icon-fm-favorite_outline: unquote('"\\e91f"');
$icon-fm-highlight_remove: unquote('"\\e920"');
$icon-fm-info: unquote('"\\e921"');
$icon-fm-info_outline: unquote('"\\e922"');
$icon-fm-search: unquote('"\\e923"');
$icon-fm-delete_forever: unquote('"\\e924"');
$icon-fm-check_circle_outline: unquote('"\\e925"');
$icon-fm-delete_outline: unquote('"\\e926"');
$icon-fm-person_remove: unquote('"\\e927"');
$icon-fm-mobile-devices: unquote('"\\e92f"');
$icon-fm-left: unquote('"\\e901"');
$icon-fm-right: unquote('"\\e902"');
$icon-fm-close: unquote('"\\e900"');
$icon-fm-menu: unquote('"\\e90a"');
$icon-fm-calendar: unquote('"\\e90b"');
$icon-fm-filter: unquote('"\\e90c"');
$icon-fm-map: unquote('"\\e90d"');
$icon-fm-route: unquote('"\\e90e"');
$icon-fm-settings: unquote('"\\e90f"');
$icon-fm-stat: unquote('"\\e910"');
$icon-fm-users: unquote('"\\e911"');
