.rating-list{
    &.large{
        margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
        span{
            font-size: 1.2em!important;
        }
    }
    span{ 
    font-size: 1em;
    color: rgba(255,255,255,.3);
    &.filled{
        color: $alert;
        // &.color-3{
        //     color: $warning;
        // }
        // &.color-4{
        //     color: $danger;
        // }
    }
    }
}