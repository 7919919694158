// basic badge
.badge{
	background-color: $primary;
	color: $white;
	&.small{
            font-size: 0.62rem;
	}

	&.badge-light {
		color: $body-color;
	}

	// round badge
	&.badge-round {
		padding: 6px 8px;
	}
}
