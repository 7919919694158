html {
  font-size: $font-size-root;
}

body {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

.wrapper {
  &.wrapper-full-page {
    height: auto;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

.content-wrapper {
  padding: 0 30px;
}

.accent-font {
  font-family: $font-family-accent;
  letter-spacing: 1.5px;
}

a {
  &:focus {
    color: darken(theme-color("primary"), 5%);
    text-decoration: none;
  }

  &.text-info {
    &:hover,
    &:focus {
      color: darken(theme-color("info"), 5%);
    }
  }
}

.dropdown-menu {
  border-radius: 1.5rem;
  overflow: hidden;
}

@media (max-width: 576px) {
  .main-panel {
    .main-content {
      .content-wrapper {
        padding: 0 15px;
      }
    }
  }
}
