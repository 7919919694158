
.lbl-status-badge{
    &.large{
        .badge{
            padding: 8px;
            font-size: .8em!important;
        }
    }
.badge{
    font-size: .7em;
    padding: 5px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    i{
        margin-bottom: 0;
        padding: 0;
        
    }
} 
}