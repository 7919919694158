.map {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;

    .map-thumbs {

        img {
            box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
            border: solid 1px #fff;
            border-radius: 6px;
        }
    }

    .tile-filter {
        filter: brightness(0.6) invert(3) contrast(4) hue-rotate(300deg) saturate(0.3) brightness(0.7);
    }

    &.dark {
        canvas {}
    }

    &.hide-overlay-img {
        .ol-overlaycontainer {
            .poi-img {
                display: none;
                visibility: hidden;
            }
        }
    }

    &.hide-overlay {
        .ol-overlaycontainer {
            .poi-circle {
                display: none;
                visibility: hidden;
            }

        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        top: 75px;
        bottom: 100px;
    }

    .carousel-inner {
        transition: height 0.3s ease-out;
        overflow: visible;
        height: auto;

        .carousel-item {
            opacity: 0;
            transition: none;
            transform: translate3d(0, 0, 0) !important;
        }

        .active,
        &.active {
            transition: opacity 1s ease-in-out;
            opacity: 1;
        }
    }


    .ol-attribution {
        >ul {
            display: none;
        }
    }

    .poi-img {
        border: solid 5px #fff;
        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
        width: 300px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000;

        img {
            height: 100%;
            max-height: 200px;
            max-width: 300px;

            &.loading {
                height: auto;
            }
        }
    }


    .poi-circle:hover {
        transform: scale(1.5);
    }

    .poi-circle {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        transition: all .2s ease-in-out;
        cursor: pointer;

    }

    @keyframes breathe {
        0% {
            transform: scale(1)
        }

        50% {
            transform: scale(0.5)
        }

        100% {
            transform: scale(1)
        }
    }

    .poi-circle.pothole:before {
        background-color: $danger;
    }

    .poi-circle.crack:before {
        background-color: $warning;
    }

    .poi-circle.lines:before {
        background-color: $alert ;
    }

    .poi-circle.pothole {
        // animation:breathe 2s infinite;
    }

    .poi-circle:before {
        content: "";
        position: absolute;
        top: -15px;
        left: -15px;
        z-index: 2;
        width: 50px;
        height: 50px;
        opacity: 0.3;
        border-radius: 100%;


    }

    .ol-popup {
        position: absolute;
        background-color: $dark-blue;
        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
        padding: 0;
        border-radius: 15px;
        border: none;
        bottom: 12px;
        left: -50px;
        white-space: nowrap;
        padding-right: 0;
        transition: all 500ms;
        transition: all 500ms;
        -moz-transition: all 500ms;
        /* Firefox 4 */
        -webkit-transition: all 500ms;
        /* Safari and Chrome */
        -o-transition: all 500ms;
        /* Opera */
        -ms-transition: all 500ms;
        /* Explorer 10 */
        color: $black;
        .pop-body{
            padding: 0 15px;
        }
        .time{
            font-size: 0.9em;
            opacity: 0.6;
            /* text-align: right; */
            /* float: right; */
            display: flex;
            clear: both;
            line-height: 1;
            margin-bottom: 10px;
            font-weight: 600;
            i{
                margin-right: 5px;
                font-size: 2em;
            }
        }
        ul {
            padding: 0;
            display: flex;
            color: $white;
            position: absolute;
            right: 10px;
            top: 10px;
            list-style: none;
            margin: 0;
            z-index: 10;
        }

        .dropdown-toggle::after {
            content: inherit;
        }

        .dropdown-menu .dropdown-item {
            width: 100%;
        }

        .carousel-indicators {
            margin-bottom: 0;
        }

        .img {
            width: 300px;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $dark-blue;

            img {
                transition: all 300ms;
                height: 100%;
                max-height: 200px;
                max-width: 300px;

                &.loading {
                    height: auto;
                }
            }
        }
        .rating{
            opacity: .8;
            border-radius: 15px;
            background-color: $light-color;
            color: $panel-bg-color;
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            margin: 10px 0;
            padding: 15px;
            line-height: 1;
            .left{
                
            }
            .right{
                display: flex;
                align-items: center;
                justify-self: center;
            }
            .stars{
                font-size: 1.8em;
                text-align: center;
                margin-top: 15px;
            }
            .lbl{
                display: block;
                color: $black;
                opacity: .3;
                text-align: left;
                margin-bottom: 10px;
                text-transform: uppercase;
            }
            .value{
                opacity: .8;
                font-size: 2em;
                text-align: left;
                    text-transform: uppercase;
            }
        }
        hr{
            border-color: $black;
            opacity: .2;
            margin: 0 10px;
            margin-bottom: 10px;
        }
        .title-info {
            padding: 10px 10px 10px 20px;
            position: relative;
            font-size: .9em;
            padding-right: 70px;
            line-height: 1.4;
            .icon{
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 2.5em;
                color: $dl-bg-color;
            }
            .line {
                position: absolute;
                top: 10px;
                bottom: 25%;
                left: 8px;
                width: 5px;
                &.hard{
                    background-color: $danger;
                }
            }

            span {
                i {
                    margin-right: 10px;
                }

            }
        }
        .address{
            display: flex;
            font-size: .9em;
            opacity: .6;
            font-weight: 600;
            align-items: center;
            i{
                margin-right: 10px;
            }
            position:relative;
            .spinner{
               margin: 0;
               position: absolute;
               right: 0;
               top: -25px;
            }
       }
        .footer-info {
            color: #fff;
            padding: 10px;
            display: grid;
            width: 100%;

            &.x2 {
                grid-template-columns: 1fr 1fr;
            }

            &.x3 {
                grid-template-columns: 1fr 1fr 1fr;
            }

            .item {
                text-align: center;
                display: block;

                .title {
                    font-size: 0.7em;
                    margin-bottom: 10px;
                    margin-top: 5px;
                    opacity: .6;
                }

                span:not(.badge) {
                    display: block;
                }

                i {
                    display: block;
                    font-size: 1.6em;
                    margin-bottom: 0;
                }
            }
        }


    }

    .ol-popup:after,
    .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .ol-popup:after {
        border-top-color: $dark-blue;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
    }

    .ol-popup:before {
        border-top-color: $dark-blue;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
    }

    .ol-popup-closer,
    .ol-popup-closer:hover,
    .ol-popup-closer:active {
        text-decoration: none;
        position: relative;
        //top: 10px;
        //right: 15px;
        z-index: 3;
        color: $white;
    }

    #popup-closer {
        position: absolute;
        right: -60px;
        border-radius: 100%;
        background: $dark-blue;
        padding: 10px 15px;
        top: -10px;
    }

    .ol-popup-closer:after {
        // content: "✖";
    }

    .ol-control {
        position: absolute;
        background-color: var(--ol-subtle-background-color);
        border-radius: 4px;
        left: 10px;
        top: 10px;

        &.ol-rotate,
        &.ol-attribution {
            display: none;
            visibility: hidden;
        }

        button {
            display: block;
            margin: 1px;
            padding: 0;
            font-weight: bold;
            text-decoration: none;
            font-size: inherit;
            text-align: center;
            height: 1.375em;
            width: 1.375em;
            line-height: .4em;
            background-color: $primary;
            border: none;
            border-radius: 2px;
            color: $white;
        }
    }
}


.kpi-wraper {
    position: fixed;
    bottom: 0;
    z-index: 1;

    .kpi-content {
        margin: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 10px;

        .item {
            box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
            height: 140px;
            min-width: 300px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            opacity: .9;
            position: relative;
            padding-top: 25px;
            .leggend {
                ul {
                    list-style: none;
                    padding: 0;
                    margin-top: 5px;

                    li {
                        display: flex;
                        align-items: center;

                        .list-value {
                            font-weight: bold;
                        }

                        .symbol-list {
                            border-radius: 100%;
                            width: 8px;
                            height: 8px;
                            margin-right: 5px;
                        }
                    }
                }
            }

            //Donut Chart 1 CSS Starts
            .donut1 {
                width: 100px;
                position: relative;

                svg {
                    position: absolute;
                }

                .ct-label {
                    fill: transparent;

                }

                .ct-outstanding .ct-slice-donut {
                    fill: transparent;
                }

                .ct-done .ct-slice-donut {
                    stroke: $primary;
                    fill: transparent;
                }


                .ct-series-a {
                    fill: $danger;
                    stroke: transparent;
                }

                .ct-series-b {
                    fill: $warning;
                    stroke: transparent;
                }

                .ct-series-c {
                    fill: $alert;
                    stroke: transparent;
                }
            }


            .desc {
                text-align: left;

                span.title {
                    position: absolute;
                    top: 15px;
                    left: 20px;
                }

                span {
                    display: block;
                    font-size: .9em;

                    &.value {
                        font-size: 3em;
                    }
                }
            }

            .icon {
                i {
                    font-size: 4em;
                }
            }
        }
    }
}