
.filter{
   
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
      color: $white;
    }
    .count-label{
        font-size: 7em;
        opacity: .2;
        text-align: right;
        margin-bottom: 30px;
    }

    @media screen and (min-height: 992px) {
      .count-label{
        position: absolute;
        bottom: 50px;
        right: 10px;
        margin-bottom: 0px;
    }
    }


    .ct-layout{
        position: relative;
        .bt-reset{
          width: 30px;
          height: 30px;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          text-align: center;
            position: absolute;
            right: 0;
            top: 0; 
        }
    }
    .noui-section{
        
        .layout-switch{
            padding-top: .5em;
        }
        .noUi-horizontal{
        margin-bottom: 0;
        font-size: .8em;
        }
    }
    .grid-x3{
      display: grid;
    grid: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 10px;
    }
}

.customizer-close.floating{
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $panel-bg-color;
  position: absolute;
  left: -70px;
  width: 50px;
  height: 50px;
}
.panel-info{
  background-color: #fff;
  border-left: solid 5px $panel-bg-color;

  .title{
    margin: 0;
  }
 
  .img-container{
    border: solid 5px #ddcde8;
    border-radius: 10px;
    overflow: hidden;
    img{
      width: 100%;
      border-radius: 10px;
    }
  }
  .rating{
    opacity: .8;
    border-radius: 15px;
    background-color: $light-color;
    color: $panel-bg-color;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 30px 0;
    padding: 15px;
    line-height: 1;
    .left{
        
    }
    .right{
        display: flex;
        align-items: center;
        justify-self: center;
    }
    .stars{
        font-size: 1.8em;
        text-align: center;
        margin-top: 15px;
    }
    .lbl{
        display: block;
        color: $black;
        opacity: .3;
        text-align: left;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
    .value{
      opacity: .8;
        font-size: 2em;
        text-align: left;
            text-transform: uppercase;
    }
}
hr{
    border-color: $black;
    opacity: .2;
    margin: 0 10px;
    margin-bottom: 10px;
}
  .time{
    font-size: 0.9em;
    opacity: 0.6;
    /* text-align: right; */
    /* float: right; */
    display: flex;
    clear: both;
    line-height: 1;
    margin-bottom: 10px;
    font-weight: 600;
    i{
        margin-right: 5px;
        font-size: 2em;
    }
}
  .head{
    text-align: center;
    background-color: $panel-bg-color;
    height: 60px;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .detail-body{
    margin-top: 50px;
    color: #000;
    .title{
      position: relative;
      margin-right: 10px;
    }
  }
  .delete-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-top: -10px;
    button{
      padding: 5px 20px;
      font-size: 0.9em;
    }

  }
}